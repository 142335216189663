import React, { Component } from 'react';
import './ImageCarousel.css';
import { BrowserRouter as Router, Route, Link } from "react-router-dom";
import { TransitionGroup, CSSTransition } from 'react-transition-group';
import ReactGA from 'react-ga';

class ImageCarousel extends React.Component {
	constructor(props) {
		super(props);

		//pass in list of images props.images
		this.state = {images: props.images, curIndex: 0, captions: props.captions, noscroll: props.noscroll};		
	}

	bunny(className, key, onClick) {
		return (<svg className={className} key={key} onClick={onClick}  width="93.628" height="134.672" viewBox="-1 -1 26.773 37.632"><g transform="translate(-82.487 -106.977)"><path d="M84.795 112.029c.042-3.094 4.206-5.898 6.329-3.401 1.56 1.835.828 6.452 1.098 9.867.075.942.016 2.809.016 2.809l5.452.068s-.06.558.132-3.14c.22-4.248.35-7.64 2.446-9.766 2.038-2.068 5.19-.54 5.3 3.04.157 5.04-.235 6.525-1.562 11.759 2.46 1.926 2.634 4.451 2.787 6.94.16 2.618-.494 5.473-2.018 7.609-1.553 2.177-4.072 3.81-6.664 4.468-2.675.68-5.674.24-8.245-.766-2.193-.858-4.41-2.267-5.61-4.292-1.367-2.305-1.54-5.285-1.223-7.946.255-2.147 2.18-5.71 2.46-6-.831-6.53-.74-8.156-.698-11.25z"/><circle cx="90.254" cy="132.408" r="2.26"/><circle cx="99.386" cy="132.408" r="2.26"/></g></svg>)
		}
	
	selectIndex(index, context) {
		if (index < 0 || index >= context.state.images.length)
			return function(){};
		return function(){ 
			ReactGA.event({
			  category: 'Navigation',
			  action: 'ImageCarousel click ' + context.state.images[index]
			})
			context.setState({curIndex: index}
		) }
	}


	render() {
		let curImage = this.state.images[this.state.curIndex]
		let circles = []

		let leftArrow = <span className="ImageCarousel-leftarrow" onClick={this.selectIndex(this.state.curIndex-1, this)} >&lt;</span>
		if (this.state.noscroll || this.state.curIndex === 0)
			leftArrow = <span></span>
		let rightArrow = <span className="ImageCarousel-rightarrow" onClick={this.selectIndex(this.state.curIndex+1, this)} >&gt;</span>
		if (this.state.noscroll || this.state.curIndex+1 >= this.state.images.length)
			rightArrow = <span></span>

		if (!this.state.noscroll)
		{
			for (let i=0; i<this.state.images.length; i++)
			{
				if (this.state.curIndex == i)
				{
					circles.push(this.bunny("ImageCarousel-bunny-selected", "circle"+i, this.selectIndex(i, this)));
				} else
				{
					circles.push(this.bunny("ImageCarousel-bunny", "circle"+i, this.selectIndex(i, this)));
				}
			}
		}
		return <div className="ImageCarousel-main">
				<TransitionGroup className="ImageCarousel-image-wrapper">		
					<CSSTransition
		                key={"fade"+this.state.curIndex}
		                timeout={100}
		                classNames="fade"
		            >
					<img src={curImage} key={this.state.images[this.state.curIndex]} alt={this.state.captions[this.state.curIndex]} />
					</CSSTransition>
					
					
				</TransitionGroup>
				<div className="ImageCarousel-caption">- {this.state.captions[this.state.curIndex]} -</div>
				
				<div className="ImageCarousel-circle-wrapper">
				{leftArrow}
				<div className="ImageCarousel-circle-panel">{circles}</div>
				{rightArrow}
				</div>
				</div>
	}
}

export default ImageCarousel;
