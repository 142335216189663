import React, { Component } from 'react';
import "./Store.css"
import { BrowserRouter as Router, Route, Link } from "react-router-dom";
import queryString from 'query-string'
import ImageCarousel from './ImageCarousel'
import ReactGA from 'react-ga';

let storeQuery = "https://www.zazzle.com/store/tleeri"
let rf = "rf=238309251290297734&tc=web"

let items = [
	{
		"title": "Vizslas Dawn Til Dusk Coaster Set",
		"story": "Hyperactive vizslas at all hours of the day. Four illustrations with painterly landscapes. Click the grey bunny icons or arrow symbol(s) above to see a closer view of each illustration.",
		"images": ["vizsla_dawn_dusk.jpg","vizsla_dawn_dusk_morn.jpg","vizsla_dawn_dusk_day.jpg", "vizsla_dawn_dusk_sunset.jpg", "vizsla_dawn_dusk_night.jpg"],
		"captions":["front","morning","day","sunset","night"],
		"url": "https://www.zazzle.com/vizslas_dawn_til_dusk_painterly_coaster_set-256404400088178408?"+rf,
		"tags": ["vizsla"]
	},
	{
		"title": "Sleeping Vizsla Pillow",
		"story": "Inspired by one of my family members' vizslas who likes to curl up like so to sleep. Features both a front and back design.",
		"images": ["sleeping_vizsla.jpg","sleeping_vizsla_back.jpg","sleeping_vizsla_luggage_tag.jpg"],
		"captions":["front","back","transfer to luggage tag"],
		"url": "https://www.zazzle.com/sleeping_vizsla_custom_text_round_pillow-256632985500356256?"+rf,
		"tags": ["vizsla"]
	},
	{
		"title": "Vizsla Love Luggage Tag",
		"story": "For this design, I wanted to try drawing the vizsla's eyes and convey its excited expression, ready to jump up to greet its owner.",
		"images": ["vizsla_love.jpg", "vizsla_love_design.jpg"],
		"captions":["front","digital design closeup"],
		"url": "https://www.zazzle.com/vizsla_love_customizable_luggage_tag-256217638158936930?"+rf,
		"tags": ["vizsla"]
	},
	{
		"title": "Frolicking Vizslas Blanket",
		"story": "Vizslas love to cuddle -> Blankets are also very cuddly -> Let's design a vizsla blanket! This is a soft and light fleece blanket; it's about 1/8\" thick. This was also my first foray into designing tiling patterns.",
		"images": ["frolicking_vizslas.jpg", "frolicking_vizslas_thickness.jpg", "frolicking_vizslas_preview.jpg", "frolicking_vizslas_design.jpg"],
		"captions": ["photo", "thickness", "preview", "digital design closeup",],
		"url": "https://www.zazzle.com/frolicking_vizslas_fleece_blanket-256544757277064164?"+rf,
		"tags": ["vizsla"]
	},
	{
		"title": "Vizsla Pups Sticker Collection",
		"story": "A collection of my cutest vizsla puppy drawings in a sticker sheet. Great for sticking onto laptops, notebooks, or cards. Phrases are customizable.",
		"images": ["vizsla_sticker_collection.jpg"],
		"captions":["preview"],
		"url": "https://www.zazzle.com/vizsla_love_stylized_drawings_and_custom_phrases_sticker-256369892428423538?"+rf,
		"tags": ["vizsla"]
	},
	{
		"title": "Snow Critters Shirt",
		"story": "Being born in the Chinese Zodiac year of the rabbit, I have a particular liking for bunnies. This design was inspired by the Zodiac cycle, with the year of the dragon following the year of the rabbit.",
		"images": ["snow_critters.jpg"],
		"captions": ["photo"],
		"url": "https://www.zazzle.com/snow_critters_t_shirt-235688726431722106?"+rf,
		"tags": ["bunny"]
	},
	{
		"title": "Sky Bunny Mug",
		"story": "I've always admired imagery of soaring blue skies, which often come up in Japanese animations (e.g. Shinkai films, Porco Rosso, Kiki's Delivery Service). For me, it evokes the feeling of following your dreams and aspirations, in a sentimental way. Here is my version.",
		"images": ["sky_bunny.jpg", "sky_bunny_design.jpg"],
		"captions": ["photo", "design"],
		"url": "https://www.zazzle.com/cloud_gazing_sky_bunny_mug-168856569453987654?"+rf,
		"tags": ["bunny"]
	},
		{
		"title": "Wading the Reef Bath Mat",
		"story": "Inspired by our experiences wading a lagoon at low tide in the Great Barrier Reef. Designed a bath mat to bring the experience back home! The design is a template, so you can personalize it by writing your own message or erasing it as you wish. The bath mat itself is fairly plush and fuzzy.",
		"images":["wading_the_great_barrier_reef.jpg"],
		"captions":["photo"],
		"url":"https://www.zazzle.com/wading_the_barrier_reef_custom_text_painterly_bath_mat-256440853833628407?"+rf,
		"tags": ["fish"]
	},
	{
		"title": "Cat Going Fishing Frosted Mug",
		"story": "Introducing a cat trying to catch goldfish swimming in your mug. Help the cat catch its prey as you drink! This mug has been through the dishwasher several times and is relatively robust. However, I recommend handwashing if you want to prevent any chips in the frosted surface.",
		"images": ["cat_going_fishing.jpg", "cat_going_fishing_back.jpg"],
		"captions": ["photo", "back", "design"],
		"url":"https://www.zazzle.com/cat_going_fishing_translucent_frosted_mug-168540661634706247?"+rf,
		"tags": ["fish"]
	},
	{
		"title": "Extra RAM Whiteboard",
		"story": "Let's augment your working memory with some extra RAM -- in the form of a dry erase board! I majored in computer science and am on the lookout for more visually punny ideas. This is my first contribution.",
		"images": ["extra_ram.jpg"],
		"captions": ["photo"],
		"url":"https://www.zazzle.com/extra_ram_dry_erase_board-256232731526996451?"+rf,
		"tags": ["tech"]
	}

]

class Store extends React.Component {
	constructor(props) {
		super(props);

		if (typeof props.filter !== "undefined")
		{
			this.state = {filter: props.filter}
		} else
		{
			this.state = {filter: "all"};
		}		
		// This binding is necessary to make `this` work in the callback
    	this.StoreItems = this.StoreItems.bind(this);
	}

	handleClick(id, e) {
		ReactGA.event({
		  category: 'Navigation',
		  action: 'Clicked ' + id
		})
		console.log(id)
	}


	StoreItems({match}) {
		//update the state if not already updated
		if (this.state.filter !== match.params.filter && typeof match.params.filter !== "undefined")
		{
			this.setState({filter: match.params.filter})
		}

		let candidates = items.slice()
		if (this.state.filter !== "all")
		{
			//filter
			candidates = items.filter(i => i["tags"].includes(this.state.filter))
			for (var i=0; i<candidates.length; i++)
				candidates[i]["noscroll"] = false
		}

		//create a "see more" store item
		let name = "See More "
		let filter = "?"+rf
		if (this.state.filter != "all")
		{
			name += " " + this.state.filter.charAt(0).toUpperCase()+ this.state.filter.slice(1) + " Designs"
			filter = "/"+this.state.filter+"?"+rf
		}
		candidates.push({
			"title": name,
			"story": "See more variations of these designs in my Zazzle store.",
			"images": ["starbright.png"],
			"captions":["-"],
			"url": storeQuery+filter,
			"tags": [],
			"noscroll": true,
			"seemore": true
		})

		//for each candidate create store item
		let storeitems = []
		for (let i in candidates)
		{
			let imageUrls = candidates[i]["images"].map(i => "https://www.tleeri.com/images/"+i)
			let linkText = "Buy/Customize here"
			if (candidates[i]["seemore"])
				linkText = "Click to see more"

			let item = <div className="Store-item" key={candidates[i]["title"]}>
							<ImageCarousel images={imageUrls} captions={candidates[i]["captions"]} noscroll={candidates[i]["noscroll"]} />
							<div className="Store-itemstory">
								<h1>{candidates[i]["title"]}</h1>
								<span>{candidates[i]["story"]}</span>
								<a href={candidates[i]["url"]} 
								onContextMenu={(e) => this.handleClick("right " + candidates[i]["title"], e)}
								onClick={(e) => this.handleClick(candidates[i]["title"], e)}> {linkText}</a>
							</div>
						</div>
			storeitems.push(item)
		}

		return (<div>{storeitems}</div>)	
	}

	render() {
		//get the tags
		let tagCounter = { "all": items.length}
		for (let i in items)
		{
			let item = items[i]
			for (let t in item["tags"])
			{
				let tag = item["tags"][t]
				if (!(tag in tagCounter))
				{
					tagCounter[tag] = 0
				}
				tagCounter[tag]++
			}
		}

		//create the filters
		let filters = []
		let allTags = []
		for (let tag in tagCounter)
			allTags.push(tag)
		allTags.sort((a,b) => tagCounter[a] - tagCounter[b] )

		for (let tag in tagCounter)
		{
			const onClick = () => this.setState({filter:tag})
			let url = "/shop/"+tag + "/"
			if (this.state.filter === tag)
				filters.push(<span className="selected" key={tag} onClick={onClick}><Link to={url}>{tag}</Link></span>)
			else			
				filters.push(<span key={tag} onClick={onClick}><Link to={url}>{tag}</Link></span>)
		}
		let filterBar = <div className="Store-filters"><span className="heading">filter</span>{filters}</div>

		return  <Router>
				<div className="Store-root">{filterBar}
				<Route path={`/shop/:filter/`} component={this.StoreItems} />
				<Route
			        exact
			        path=""
			        component={this.StoreItems} 
			      />
				</div>
				</Router>
	}

}

export default Store;