import React, { Component } from 'react';
import './BurgerMenu.css';
import { BrowserRouter as Router, Route, Link } from "react-router-dom";
import ReactGA from 'react-ga';

class BurgerMenu extends React.Component {
	constructor(props) {
		super(props);
		this.state = {show: false};		
		// This binding is necessary to make `this` work in the callback
    	this.handleClick = this.handleClick.bind(this);
	}

	handleClick(id, e) {
		this.setState({show: !this.state.show})
		ReactGA.event({
		  category: 'Menu',
		  action: 'Clicked ' + id
		})
	}

	render() {
		var menuimage = <img className="BurgerMenu-image clickable" src="/burgermenucropped.png" alt="burgermenu" onClick = {this.handleClick} />
		if (this.state.show) {
			var menuitems =  <div className="BurgerMenu-slide padded">
								<span onClick={(e) => this.handleClick("shop", e)}><Link to="/">shop</Link></span><br />
								<span onClick={(e) => this.handleClick("about", e)}><Link to="/about/">about</Link></span>
							 </div>
			var menu = <div className="BurgerMenu-main">{menuimage}{menuitems}</div>
			return menu; 
		} else
		{
			return <div className="BurgerMenu-main">{menuimage}</div>;
		}

	}
}

export default BurgerMenu;
