import React, { Component } from 'react';
import logo from './logo.svg';
import './App.css';
import BurgerMenu from './BurgerMenu.js'
import Store from './Store.js'
import { BrowserRouter as Router, Route, Link } from "react-router-dom";
import ReactGA from 'react-ga';
import createHistory from 'history/createBrowserHistory'
import fetch from 'isomorphic-fetch'

fetch('/cdn-cgi/trace').then(result => result.text())
.then(text => {
    if (text.includes('loc=US')) {
      ReactGA.initialize('UA-17464097-3');
    } else {
      ReactGA.initialize('UA-17464097-3', {
        gaOptions: {
          storage: 'none'
        }
      });
    }
    ReactGA.set({ anonymizeIp: true });
    firePageView(window.location);
  }
  ,
  error => {
    console.log("error");
    ReactGA.initialize('UA-17464097-3', {
      gaOptions: {
        storage: 'none'
      }
    });
    ReactGA.set({ anonymizeIp: true });
    firePageView(window.location);
  }
)



let firePageView = function(location) {
    let pageValue = (location.pathname + location.search + location.hash.replace('#','')).replace('//','/')
    ReactGA.pageview(pageValue)
}


const history = createHistory()
history.listen((location, action) => {
   firePageView(location)
});

class App extends Component {
  constructor(props) {
    super(props)
    this.handleClick = this.handleClick.bind(this)
    this.handleScroll = this.handleScroll.bind(this)
    let state = {}
    state["dataloaded"] = false
    this.state = state
    this.Shop = this.Shop.bind(this)
  }

  componentDidMount() {
    window.addEventListener('scroll', this.handleScroll, {passive: true});

    if (!this.state["dataloaded"])
    {
      let xmlhttp = new XMLHttpRequest()
      let url = "https://www.tleeri.com/saleinfo.json"
      let context = this
      xmlhttp.onreadystatechange = function(e) {
        if (this.readyState == 4 && this.status == 200)
        {
          let data = JSON.parse(this.responseText)
          let info = data["message"]
          let state = {}
          state["message"] = info
          state["dataloaded"] = true
          context.setState(state)
        } 
      }
      xmlhttp.open("GET", url, true)
      xmlhttp.send()
    }
  }

  componentWillUnmount() {
    window.removeEventListener('scroll', this.handleScroll);
  }

  Shop({match}) {
    let saleInfo = <span className='App-sale'>{this.state["message"]}</span>
    let affiliateDisclaimer = <p>Note: The product links on this page are affiliate links, which means if you buy something through the link, I'll get a small referral bonus at no additional cost to you.</p>
    return (<div>
               <h1>Welcome</h1>
            
            I draw cute animal-themed designs. I'm always looking for cute and quirky ways to match the drawing with its physical object. My current favorite subjects are vizslas (inspired by my family's vizslas), bunnies, and fish.

            <p>
            More highlights and info on my designs below. Click the links to view the design in my Zazzle store, where you can customize the text, images, and colors. You can scroll through pictures by clicking on either the grey bunny icons or the grey arrows. Thank you for visiting!
            </p>
            {affiliateDisclaimer}
            {saleInfo}
            <Store />
            </div>)
  }

  About({match}) {
    return (
      <div>
      <h1>About me</h1>
      <p>I enjoy creating things. I'm an engineer by day and an artist when there's free time.</p>

      <p>My shop designs are currently inspired by two main subjects – <a href="https://en.wikipedia.org/wiki/Vizsla">vizslas</a> and fish. The former is due to the cute and hyperactive vizslas owned by some of my family members, and the latter is due to my experiences fishkeeping. There may be some other cute animals and computer science puns thrown in the mix as well. =)</p>

      <h1>Why tleeri?</h1>
      tleeri comes from the scientific name of the <a href="https://en.wikipedia.org/wiki/Pearl_gourami">pearl gourami</a> (Trichopodus leerii/leeri), one of my favorite tropical fish -- beautiful and graceful, but often temperamental toward their own kind. Unfortunately to better suit apartment-living, I moved to smaller aquariums and stopped keeping them as they can get quite large (4 to 5 inches). Would love to keep one again if/when I get a larger aquarium.
      <img src="/images/tleeri.png" alt="pearl gourami painting"/><br />
      ^ A digital painting of my female pearl gourami.
      </div>)
  }

  handleClick(cat, id, e) {
    ReactGA.event({
      category: cat,
      action: 'Clicked ' + id
    })
  }

  handleScroll(e) {
    ReactGA.event({
      category: 'Scroll',
      action: 'scroll'
    })
  }

  render() {
    return (     
        <Router>
        <div className="App-root">
        <div className="App-header col-12">
            <div className="App-logo">tleeri</div>
            <div className="App-navbar">
              <span onClick={(e) => this.handleClick("Navigation", "shop", e)}><Link to="/">shop</Link></span>
              <span onClick={(e) => this.handleClick("Navigation", "about", e)}><Link to="/about/">about</Link></span>
            </div>
            <img className="App-flavorimage" src="/images/cloudbunny.png" alt="" />
            <BurgerMenu />
        </div>
        <div className="App-leftbar App-col-side"></div>
        <div className="App-main App-col-main">           
          <Route exact path="/" component={this.Shop} />
          <Route path="/shop/" component={this.Shop} />
          <Route path="/about/" component={this.About} />
        </div>
        <div className="App-rightbar App-col-side"></div>
        </div>
        </Router>
    );
  }
}

export default App;
